<template>
  <div class="order-card-container">
    <template>
      <!-- price card -->
      <b-card
        :style="activatePaymentKey ? 'margin-top: 10px;' : ''"
        header-class="price-header"
        header="PRICE DETAILS"
      >
        <template v-if="$store.state['checkout'].checkoutLoading == true">
          <b-skeleton
            v-for="i in 8"
            :key="i"
            class="mb-3"
            animation="wave"
            :style="`width: ${Math.floor(Math.random() * 31) + 50}%;`"
          ></b-skeleton>
        </template>

        <blockquote
          v-if="$store.state['checkout'].checkoutLoading == false"
          class="blockquote mb-0"
        >
          <b-row class="mb-2">
            <b-col>
              <b-card-text class="price-details"
                >Subtotal <br />({{
                  checkoutData.items.length
                }}
                items)</b-card-text
              >
            </b-col>
            <b-col>
              <b-card-text class="float-right price-details">
                ₹ {{ checkoutData.totalPriceBeforeTax }}</b-card-text
              >
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-card-text class="price-details color-red"
                >Discount:
              </b-card-text>
            </b-col>
            <b-col>
              <b-card-text class="float-right price-details color-red">
                - ₹ {{ checkoutData.totalDiscount }}
              </b-card-text>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-card-text class="price-details">Tax:</b-card-text>
            </b-col>
            <b-col>
              <b-card-text class="float-right price-details">
                ₹ {{ checkoutData.totalTaxes }}
              </b-card-text>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="!checkoutData.totalDeliveryCharge == 0">
            <b-col>
              <b-card-text class="price-details">Shipping: </b-card-text>
            </b-col>
            <b-col>
              <b-card-text class="float-right price-details">
                ₹ {{ checkoutData.totalDeliveryCharge }}</b-card-text
              >
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col>
              <b-card-text class="price-total-amount"
                >Total Amount
              </b-card-text>
            </b-col>
            <b-col>
              <b-card-text class="float-right price-total-amount">
                ₹ {{ checkoutData.totalPrice }}</b-card-text
              >
            </b-col>
          </b-row>
        </blockquote>
        <template
          v-if="
            $options.components['address-no-address'] &&
            $store.state['checkout'].checkoutLoading === 'errorAddress'
          "
        >
          <address-no-address />
        </template>
      </b-card>

      <!-- // -->
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "price-card",
  props: {
    activatePaymentKey: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      defaultAddress: (state) => state.address.defaultAddress,
      selectedCoupon: (state) => state.checkout.selectedCoupon,
      checkoutData: (state) => state.checkout.checkoutData,
      cartData: (state) => state.checkout.cartData,
    }),
  },
  watch: {
    async defaultAddress(newVal, oldval) {
      try {
        if (oldval.city) {
          if (oldval._id != newVal._id) {
            await this.$store.dispatch("checkout/checkoutFinal");
          }
        }
      } catch {
        console.log("");
      }
    },
    async selectedCoupon(newVal, oldval) {
      try {
        if (oldval._id) {
          if (oldval._id != newVal._id) {
            await this.$store.dispatch("checkout/checkoutFinal");
          }
        }
      } catch {
        console.log("");
      }
    },
  },
};
</script>
<style scoped>
.invert-color {
  filter: invert(1);
}

.address-details {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 11px;
  text-transform: capitalize;
  color: #9e9e9e;
}
.color-red {
  color: red;
}
.text-danger {
  color: #fe7b08 !important;
}

.price-header {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.card-custom-class {
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 0.25rem;
}
.price-details {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.price-total-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 19px;
  color: #14982b;
}

.btn-outline-primary {
  width: 180px;
  height: 45px;
}

.btn-primary {
  width: 180px;
  height: 45px;
  border-radius: 3px;
}

.btn-outline-primary:hover {
  width: 180px;
  height: 45px;
}
</style>
